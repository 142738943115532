import './App.scss';
import {
  FbShare,
  Logo,
  MediumIcon,
  NewEdition,
  TelegramIcon,
  TwitterIcon, TwShare,
  WebsiteIcon,
  YoutubeIcon
} from './assets/icons';
import book from './assets/book.png'
import rocket from './assets/rokket.png'
import Form from './Form';
import { FacebookShareButton, TwitterShareButton } from 'react-share';


function App() {
  return (
    <div className='app'>

      <div className="container">
        <div className="header">
          <Logo />
        </div>

        <div className="main">
          <div className="main_column">
            <div className="main_column-left">
              <div className='main-label-wrapper'>
                <NewEdition />
                <img className='main-img' src={book} alt="book"/>
              </div>
              <span className='main-count-page'>20 <span>PAGES</span></span>
            </div>
            <div className="main_column-right">
              <h1 className='main-title'><span className='for-desc black'>— </span><span>Free Exclusive</span> —</h1>
              <span className='main-title-text'> The DeFi Security Handbook</span>
              <span className='main-text-link'>by DEFIYIELD <a target='_blank' href="https://defiyield.app/" rel="noreferrer">App</a></span>
              <span className='main-description'>As security experts, we want to help all users stay safe and confident investing in DeFi.</span>
              <span className='main-description-bolt'>The Handbook helps you to understand the nature of scams, hacks and risks and how to DYOR (do your own research).</span>
              <span className='main-text'>Get your DEFI SECURITY HANDBOOK copy and join the community!</span>

              <div className="form">
                <Form />
              </div>

              <div className="share">
                <FacebookShareButton quote={'I recommend everyone in DeFi to read The DeFi Ultimate Book - The Wall Street Era is Over by DEFIYIELD'} hashtag={'#defiyield, #defiyield_community'} url={'https://defibook.io/'}>
                <button><FbShare /> Share</button>
                </FacebookShareButton>
                <TwitterShareButton title={'I recommend everyone in DeFi to read The DeFi Ultimate Book - The Wall Street Era is Over by DEFIYIELD'} via={'@Wendel Pereira'} hashtags={['defiyield', 'defiyield_community']} url={'https://defibook.io/'}>
                <button><TwShare /> Tweet</button>
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="footer_wrapper">
            <div className="footer-img">
              <img src={rocket} alt=""/>
            </div>
            <div className="footer-links">
              <a target='_blank' className='footer-link' href="https://t.me/defiyield_app" rel="noreferrer">
                <span className='footer-link_icon'><TelegramIcon /></span>
                <span className='footer-link_text'>TELEGRAM</span>
              </a>

              <a target='_blank' className='footer-link' href="https://twitter.com/defiyield_app" rel="noreferrer">
                <span className='footer-link_icon'><TwitterIcon /></span>
                <span className='footer-link_text'>TWITTER</span>
              </a>

              <a target='_blank' className='footer-link' href="https://blog.defiyield.app/" rel="noreferrer">
                <span className='footer-link_icon'><MediumIcon /></span>
                <span className='footer-link_text'>MEDIUM</span>
              </a>

              <a target='_blank' className='footer-link' href="https://www.youtube.com/channel/UCMCGy1ExKesm8YlcKGAFRGA" rel="noreferrer">
                <span className='footer-link_icon'><YoutubeIcon /></span>
                <span className='footer-link_text'>YOUTUBE</span>
              </a>

              <a target='_blank' className='footer-link' href="https://defiyield.app/" rel="noreferrer">
                <span className='footer-link_icon'><WebsiteIcon /></span>
                <span className='footer-link_text'>WEBSITE</span>
              </a>
            </div>
          </div>
        </div>
      </div>


      {/*<div className='main'>*/}
      {/*  <div className='main-left'>*/}
      {/*    <div className='small-logo'><SmallLogo/></div>*/}
      {/*    <div className='rocket'>*/}
      {/*      <img src={rocket} alt="rocket"/>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='main-right'>*/}
      {/*    <div className='big-logo'><BigLogo/></div>*/}
      {/*    <div className='text'>Get the Hottest 🔥 news and opportunities on DeFi, Tutorials and latest interviews!</div>*/}
      {/*    <div className='description'>The best insights delivered straight to you!</div>*/}
      {/*    <div className='form-wrapper'><Form /></div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className='footer'>*/}
      {/*  <div className='footer_socials'>*/}
      {/*    <a href='https://t.me/defiyield_app' target='_blank' className='footer_social-item' rel="noreferrer"><TelegramIcon/></a>*/}
      {/*    <a href='https://twitter.com/defiyield_app' target='_blank' className='footer_social-item' rel="noreferrer"><TwitterIcon/></a>*/}
      {/*    <a href='https://blog.defiyield.app/' target='_blank' className='footer_social-item' rel="noreferrer"><MediumIcon/></a>*/}
      {/*    <a href='https://www.youtube.com/channel/UCMCGy1ExKesm8YlcKGAFRGA' target='_blank' className='footer_social-item' rel="noreferrer"><YoutubeIcon/></a>*/}
      {/*  </div>*/}

      {/*  <div className='footer_text'>Farm easy — we take care.</div>*/}
      {/*  <div className='footer_desc'>*/}
      {/*    DEFIYIELD can help you to stay safe and farm successfully on different blockchains. If you want to start using it now, click the link below to find out more and connect your wallet.*/}
      {/*  </div>*/}

      {/*  <a href="https://defiyield.app/" className='footer_link'>*/}
      {/*    <span><LogoShort/></span>*/}
      {/*    <span>Go to the homepage  → </span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </div>
  );
}

export default App;
