import React, { useEffect, useMemo, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import classNames from 'classnames';
import qs from 'qs';
import { IncorectInputIcon } from './assets/icons';

const RenderForm = ({ status, onValidated, message}) => {

  const [error, setError] = useState(null)

  const [state, setState] = useState({
    email: '',
    name: '',
  });

  useEffect(() => {
    if (status === 'success') {
      setError(null)
      setState({ email: '', name: ''})
    }
  }, [status])

  useEffect(() => {
    setError(message)
  }, [message])

  const disable = !state.email || !state.name;

  const onInputChange = ({ target }) => {
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const submit = () => {
    onValidated({
      EMAIL: state.email,
      FNAME: state.name,
    });
  };

  return (
      <div className='form'>
        <div className='form-input-wrapper'>
          <span>
          <input
            type="text"
            value={state.name}
            className='input'
            name="name"
            placeholder="Name or nickname"
            onChange={onInputChange}
          />
        </span>

          <span>
          <input
            type="text"
            value={state.email}
            className='input'
            name="email"
            placeholder="Email"
            onChange={onInputChange}
          />
            {error?.includes('An email') && (
              <div className='error'>
                <IncorectInputIcon/> Invalid email
              </div>
            )}
        </span>
        </div>
        {
          status !== 'success' ? (
            <button
              disabled={disable}
              className={classNames('button', {
                'disable': disable,
              })}
              onClick={submit}
            >
              Get Your Copy
            </button>
          ) : (
            <div className='status-thx'>
              {/*<span><ThxIcon/></span>*/}
              <span>Thanks for your subscription! Check your email.</span>
            </div>
          )
        }
      </div>
  );
};

const Form = () => {
  const getMailchimpUrl = () => {
    const query = qs.stringify({
      u: 'e351261c9b1b8c71514323d7d',
      id: '1ac2b8db21',
    });

    return `https://defiyield.us2.list-manage.com/subscribe/post?${query}`;
  };
  const url = useMemo(() => getMailchimpUrl(), []);
  console.log(url)
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <RenderForm
          status={status}
          message={message}
          onValidated={(formData) => {
            subscribe(formData);
          }}
        />
      )}
    />
  );
};

export default Form;
